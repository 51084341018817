<template>
  <div class="root">

<h>系统首页</h>


    </div>
</template>

<script>



export default {
  name:"Index",
  data() {
    return {

    }

  },


  methods: {

    },


  }

</script>

<style scoped>


</style>